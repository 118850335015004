//@ts-nocheck
import { useEffect } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import './custom-checkbox.css'
import { useAppSelector } from 'hooks'

const CountryFilter = ({ userAuthInfo, regions, setRegions }) => {
    const {
        countries_filter
    } = useAppSelector((state) => state.filters.filters)
    const regionsAndCountries = userAuthInfo?.regions;

    let countries: any = [];
    for (let region of regionsAndCountries) {
        countries = [...countries, ...region.countries]
    }

    const initialRegions = regionsAndCountries.map((region) => ({
        // ...(countries.length === countries_filter.length) && {checked: true},
        checked: region.countries.every((country) =>
            countries_filter.some((e) => e.toUpperCase() === country.toUpperCase())
        ),
        label: region.region,
        value: region.region,
        children: region.countries.map((country) => ({
            ...(countries.length !== countries_filter.length && countries_filter.some(e => e.toUpperCase() === country.toUpperCase())) && {checked: true},
            label: country,
            value: country,
        })),
    }))

    useEffect(() => {
        setRegions(initialRegions)
    }, [])

    const onChange = (currentNode, selectedNodes) => {
        setRegions((prevRegions) => (
            prevRegions.map((region) => ({
                ...(selectedNodes.some(e => e.label === region.label)) && {checked: true},
                ...(region.children.some(e => e.label === currentNode.label) || (region.label === currentNode.label && currentNode.expanded) || selectedNodes.find(e => e.label === region.label)?.expanded) && {expanded: true},
                label: region.label,
                value: region.value,
                children: region.children.map((country) => ({
                    ...(selectedNodes.some(e => e.label === country.label)) && {checked: true},
                    label: country.label,
                    value: country.value,
                })),
            }))
        ));
    };

    return (
        <div className="mt-4">
            <label id='Country-Label' className='text-sm font-bold text-gray-9'>Country/Region</label>
            <DropdownTreeSelect
                aria-labelledby='Country-Label'
                className="custom-dropdown mt-1 text-sm"
                data={regions}
                showPartiallySelected={true}
                onChange={onChange}
                inlineSearchInput={true}
            />
        </div>
    )
}

export default CountryFilter