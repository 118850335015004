import LandingLayout from 'views/Landing/LandingLayout'
import { Scrollbar } from 'react-scrollbars-custom'
import { NavLink } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import ArrowRight from 'assets/images/arrow-right.svg'
import CloseWithoutOutline from 'assets/images/close-without-outline.svg'

const Privacy = () => {
  const { t } = useTranslation()
  return (
    <LandingLayout pageName="terms_and_privacy">
      <div className="w-full h-full">
        <div className="h-full pl-10 pr-5 py-3 prose bg-white rounded-lg max-w-none">
          <div className="flex flex-row items-center justify-between">
            <NavLink
              to="/"
              className="flex flex-row items-center h-12 p-2 mr-6 text-base font-medium no-underline bg-gray-300 border rounded rounded-lg hover:opacity-90 pr-4"
            >
              <img
                src={CloseWithoutOutline}
                alt="Close"
                className="pr-[8px] pb-[4px]"
              />
              {t('close')}
            </NavLink>
            <h1 className="flex justify-center pt-4 pb-4 font-semibold h-[10%]">
              {t('privacy')}
            </h1>
            <NavLink
              to="/terms"
              className="flex flex-row items-center h-12 p-2 mr-6 text-base font-medium no-underline bg-gray-300 border rounded rounded-lg hover:opacity-90"
            >
              {t('goToTerms')}
              <img src={ArrowRight} alt="Go to Terms" className="pl-[8px]" />
            </NavLink>
          </div>
          <Scrollbar style={{ height: '82%' }} minimalThumbSize={200}>
            <p className="pb-2  pr-5">{t('aEGT')}</p>
            <h1 className="text-sm font-bold uppercase">{t('privacy1')}</h1>
            <p className="pb-2 flex items-center justify-center">
              <table className="border-2 w-[90%] border-gray-3">
                <tbody>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('yourDataController')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        GBT US LLC <br /> 101 Hudson Street
                        <br /> 34th Floor <br />
                        Jersey City, NJ 07302
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('whatInformationCollect')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('weCollectInfo')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('howUseInformation')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('weUSeInfo')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('howShareInformation')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('weHaveContract')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('marketingChoices')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('weUseYourInfo')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('internationalTransfers')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('weTransferInfo')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('yourRights')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('youHaveTheRightExp')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('changes')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        {t('changesExp')}
                      </p>
                    </td>
                  </tr>
                  <tr className="even:bg-light-gray-bg odd:bg-white">
                    <td className="pb-2 pt-2 pr-20 pl-4">
                      <p className="text-sm leading-none text-gray-900">
                        {t('contactUs')}
                      </p>
                    </td>
                    <td className="pb-2 pt-2 pr-12">
                      <p className="text-sm leading-none text-gray-900">
                        <Trans
                          i18nKey="contactUsExp"
                          components={{ break: <br />, italic: <i /> }}
                        />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
            <h1 className="text-sm font-bold uppercase">{t('privacy2')}</h1>
            <p className="pb-2  pr-5">
              <Trans
                i18nKey="privacy2Exp"
                components={{ break: <br />, bold: <strong /> }}
              />
            </p>
            <h1 className="text-sm font-bold uppercase">{t('privacy3')}</h1>
            <p className="pb-2  pr-5">
              <Trans
                i18nKey="privacy3Exp"
                components={{ break: <br />, bold: <strong /> }}
              />
            </p>
            <h1 className="text-sm font-bold uppercase">{t('privacy4')}</h1>
            <p className="pb-2  pr-5">
              <Trans i18nKey="privacy4Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold uppercase">{t('privacy5')}</h1>
            <p className="pb-2  pr-5">
              <Trans
                i18nKey="privacy5Exp"
                components={{ break: <br />, bold: <strong /> }}
              />
            </p>
            <h1 className="text-sm font-bold uppercase">{t('privacy6')}</h1>
            <p className="mb-2  pr-5">{t('privacy6Exp')}</p>
            <br />
            <h1 className="text-sm font-bold">{t('privacy7')}</h1>
            <p className="pb-2  pr-5">{t('privacy7Exp')}</p>
            <h1 className="text-sm font-bold uppercase">{t('privacy8')}</h1>
            <p className="pb-2  pr-5">{t('privacy8Exp')}</p>
            <h1 className="text-sm font-bold uppercase">{t('privacy9')}</h1>
            <p className="pb-2  pr-5">{t('privacy9Exp')}</p>
            <h1 className="text-sm font-bold uppercase">{t('privacy10')}</h1>
            <p className="pb-2  pr-5">{t('privacy10Exp')}</p>
            <h1 className="text-sm font-bold uppercase">
              11. {t('contactUs')}
            </h1>
            <p className="pb-2  pr-5">
              <Trans
                i18nKey="privacy11Exp1"
                components={{ break: <br />, italic: <i /> }}
              />
            </p>
            <p>{t('privacy11Exp2')}</p>
          </Scrollbar>
        </div>
      </div>
    </LandingLayout>
  )
}

export default Privacy
