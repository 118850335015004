import { Routes, Route, useNavigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

// import views
import Landing from 'views/Landing/Landing'
import TravelSummary from 'views/TravelSummary'
import GLReporting from 'views/GLReporting';
import Overview from 'views/TravelSummary/Tabs/Overview'
import TravelGuidelines from 'views/TravelSummary/Tabs/TravelGuidelines'
import Meals from 'views/TravelSummary/Tabs/Meals'
import ComplianceTrends from 'views/TravelSummary/Tabs/ComplianceTrends'
import SavingsOpportunity from 'views/SavingsOpportunity'
import BookingType from 'views/SavingsOpportunity/Tabs/BookingType'
import DigitalConferencing from 'views/SavingsOpportunity/Tabs/DigitalConferencing'
import TopContributors from 'views/SavingsOpportunity/Tabs/TopContributors'
import Terms from './views/Terms/Terms'
import Privacy from 'views/Privacy/Privacy'
import Faqs from './views/Faqs/Faqs'
import MaintenancePage from './views/Maintainence';

// import components
import 'App.css'
import PreLoader from './components/base/PreLoader/PreLoader'
import errorHandler from 'utils/error'
import { OktaErrorComponent, PageNotFound, SomethingWentWrong } from 'components/ErrorFallback'
import { config } from 'config'
import { SecureRoute } from 'components/oktaUtils/SecureRoute'
import ReportingHub from 'views/ReportingHub';
import GlOverview from 'views/GLReporting/Tabs/Overview';

function getOktaAuthConfig() {
  const { OKTA_ISSUER, OKTA_CLIENTID, WELLS_FARGO_BASE_URL } = config
  if (OKTA_ISSUER === undefined)
    throw new Error('Missing OKTA_ISSUER environment variable')
  if (OKTA_CLIENTID === undefined)
    throw new Error('Missing OKTA_CLIENTID environment variable')
  if (WELLS_FARGO_BASE_URL === undefined)
    throw new Error('Missing QUEST_BASE_URL environment variable')

  return {
    issuer: OKTA_ISSUER,
    clientId: OKTA_CLIENTID,
    redirectUri: window.location.origin + '/login/callback',
    pkce: true,
  }
}

const oktaAuth = new OktaAuth(getOktaAuthConfig())

function App() {
  const navigate = useNavigate()

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(
      toRelativeUrl(
        originalUri || '/',
        window.location.origin
      ),
      {
        replace: true,
      }
    )
  }

  return (
    <>
      <ErrorBoundary
        FallbackComponent={SomethingWentWrong}
        onError={errorHandler}
      >
        {config.MAINTAINENCE === "True" ? (
          <MaintenancePage />
        ) : (
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              <Route
                path="/login/callback"
                element={
                  <LoginCallback
                    loadingElement={<PreLoader />}
                    errorComponent={OktaErrorComponent}
                  />
                }
              />
              <Route path="/" element={<SecureRoute />}>
                <Route path="" element={<Landing />} />
                <Route path="/travel-summary" element={<TravelSummary />}>
                  <Route index element={<Overview />} />
                  <Route path="overview" element={<Overview />} />
                  <Route path="travel-guidelines" element={<TravelGuidelines />} />
                  <Route path="meals" element={<Meals />} />
                  <Route path="compliance-trends" element={<ComplianceTrends />} />
                </Route>
                <Route path="/savings-opportunity" element={<SavingsOpportunity />}>
                  <Route index element={<BookingType />} />
                  <Route path="booking-type" element={<BookingType />} />
                  <Route path="digital-conferencing" element={<DigitalConferencing />} />
                  {/* <Route path="top-contributors" element={<TopContributors />} /> */}
                </Route>
                <Route path="/gl-reporting" element={<GLReporting />}>
                  <Route index element={<GlOverview />} />
                  <Route path="gl-overview" element={<GlOverview />} />
                </Route>
                <Route path="/reporting-hub" element={<ReportingHub />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/faqs" element={<Faqs />} />
              </Route>
            </Routes>
          </Security>
        )}
      </ErrorBoundary>
    </>
  )
}

export default App
