//@ts-ignore
//@ts-nocheck
import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// import components
import Layout from 'components/layout'
import { useAppSelector } from 'hooks'
import { config } from 'config'
// import LastUpdatedDialog from 'components/lastUpdated'
// import { ThreeDotsAnimation } from 'components/Loading'


const SavingsOpportunityLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const {
    dateRange: { startDate, endDate },
    compare_with_year,
    business_purpose_filter,
    travel_type_filter,
    lob_filter
  } = useAppSelector((state) => state.filters.filters)
  const { t } = useTranslation()
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)
  const last_updated = useAppSelector((state)=> state.userInfo.userAuthInfo?.last_updated)
  const [isOpen, setIsOpen] = useState(false)

  const status = 'success'
  // const bpf = business_purpose_filter.map(function(x: any){ return x.value; })

  function handleClickDialog() {
    setIsOpen(true)
  }

  return (
    <Layout>
      <div className="w-full h-full overflow-y-auto px-7 md:px-12 xl:px-8 2xl:mx-2.5 2xl:px-12 py-6 bg-light-gray-bg">
        <div className="flex flex-row items-center justify-between text-dark-blue md:h-[5%]">
          <h1 className="text-5xl font-bold tracking-wide">
            {t('savingsOpportunityHeader')} <span className="text-base">{lob_filter?.label.replace(/ -.*?\(/, ' (')} : {startDate} to {endDate}</span>
          </h1>
          <h2 className="text-xl tracking-wide">
            <div>
                <span className="font-semibold"> {full_name} </span>
            </div>
          </h2>
        </div>
        {children}
        <div className="flex flex-row items-end justify-between text-xs leading-[.75rem] text-gray-9 h-[5%]">
          {/* <p>
            Showing data from <strong>{startDate}</strong> to <strong>{endDate}</strong> Filtered by <strong>{`${bpf.length === 3 ? 'All' : bpf.join(' & ')} ${bpf.length !== 0 ? '(Business Purpose), ' : ''}`}</strong><strong>{travel_type_filter}(Domestic / International)</strong> & <strong>{lob_filter?.label}(LOB)</strong>{ !pathname.includes('top-contributors') ? (` compared to `) : ''}<strong>{ !pathname.includes('top-contributors') ? compare_with_year : ''}</strong>
          </p> */}
          <p>
            Showing data from <strong className="text-dark-blue">{startDate}</strong> to <strong className="text-dark-blue">{endDate}</strong> Filtered by <strong className="text-dark-blue">{business_purpose_filter}(Business Purpose)</strong>, <strong className="text-dark-blue">{travel_type_filter}(Domestic / International)</strong> & <strong className="text-dark-blue">{lob_filter?.label}(LOB)</strong> compared to <strong className="text-dark-blue">{compare_with_year}</strong>
          </p>
          <div>
            {/* {status === 'loading' ? (
              <ThreeDotsAnimation aspectRatio="meet" />
            ) : null} */}
            {status === 'success' ? (
              <>
                <span className="font-normal leading-4">
                  {t('lastUpdatedTill')}
                </span>{' '}
                <button
                  onClick={() => handleClickDialog()}
                  className="hover:opacity-80 hover:text-primary-blue hover:underline"
                >
                  <span className="underline font-bold decoration-1">
                    {last_updated}
                  </span>
                </button>
              </>
            ) : null}
            {/* {status === 'error' ? (
              <span className="pl-2 text-sm text-gray-600">
                {t('somethingWentWrongTryAgain')}
              </span>
            ) : null} */}
          </div>
        </div>
        {/* <LastUpdatedDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          dataSourcesData={data}
        /> */}
      </div>
    </Layout>
  )
}

export default SavingsOpportunityLayout
