import LandingLayout from 'views/Landing/LandingLayout'
import { Scrollbar } from 'react-scrollbars-custom'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import assets
import CloseWithoutOutline from 'assets/images/close-without-outline.svg'

const Faqs = () => {
  const { t } = useTranslation()
  return (
    <LandingLayout pageName="terms_and_privacy">
      <div className="w-full h-full">
        <div className="h-full pl-10 pr-5 py-3 prose bg-white rounded-lg max-w-none">
          <div className="flex flex-row items-center justify-start">
            <span className="w-1/2">
              <NavLink
                to="/"
                className="flex flex-row text-black items-center h-12 w-[120px] p-2 mr-6 text-base font-medium no-underline bg-gray-300 border rounded rounded-lg hover:opacity-90 pr-4"
              >
                <img
                  src={CloseWithoutOutline}
                  alt="Close"
                  className="pr-[8px] pb-[4px]"
                />
                {t('close')}
              </NavLink>
            </span>
            <h1 className="pt-2 font-semibold h-[10%] w-1/2 mt-6">
              {t('faq')}
            </h1>
          </div>

          <Scrollbar style={{ height: '80%' }} minimalThumbSize={200}>
            <h1 className="text-lg font-semibold text-blue">{t('faq1')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns1')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq2')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns2')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq3')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns3')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq4')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns4')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq5')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns5')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq6')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns61')}
              <br></br>
              {t('faqAns62')}
              <br></br>
              {t('faqAns63')}
              <br></br>
              {t('faqAns64')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq7')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns7')}
            </p>
            <h1 className="text-lg font-semibold text-blue">{t('faq8')}</h1>
            <p className="pb-2 pr-5 text-base font-normal leading-7">
              {t('faqAns8')}
            </p>
          </Scrollbar>
          <div className=" flex items-center justify-center flex flex-row  ">
            <span className="text-blue font-semibold text-lg">
              {t('haveQuestions')}
            </span>
            <a
              className="bg-blue rounded-full h-[36px] w-auto ml-4 drop-shadow-xl cursor-pointer no-underline flex items-center"
              href="mailto:deepak.dobhal@amexgbt.com"
            >
              <span className="text-white text-[12px] font-semibold pr-4 pl-4 ">
                {t('contactUs')}
              </span>
            </a>
          </div>
        </div>
      </div>
    </LandingLayout>
  )
}

export default Faqs
