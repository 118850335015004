import React from 'react'

function VizLoader() {
  return (
    <div className="relative bg-white border rounded border-light-gray drop-shadow-performanceBox h-[336px] md:min-w-[554px] animate-pulse">
        <div className="flex flex-row justify-between items-end h-[50px]">
            <div className="h-[75%] w-[30%] ml-6 border rounded-xl bg-light-gray">
            </div>
            <div className="h-[75%] w-[20%] mr-6 border rounded-xl bg-light-gray">
            </div>
        </div>
        <div className="border rounded-xl h-[238px] w-11/12 mx-auto my-[2%] bg-light-gray"></div>
    </div>
  )
}

export default VizLoader