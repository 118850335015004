//@ts-nocheck
import { format, parse, subDays } from 'date-fns';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export default function DatePickerSingle({selectedDate, setSelectedDate}) {
  return (
    <DayPicker
        mode="single"
        toDate={new Date()}
        fromDate={subDays(new Date(), 30)}
        selected={parse(selectedDate, 'yyyy-MM-dd', new Date())}
        onSelect={(pickedDate) => setSelectedDate(format(
            new Date(pickedDate),
            "yyyy-MM-dd"
        ))}
        modifiersStyles={{
            selected: {
                backgroundColor: 'rgba(102,169,226,0.4)',
                color: '#006FCF',
            }
        }}
    />
  );
}