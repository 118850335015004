import LandingLayout from 'views/Landing/LandingLayout'
import { Scrollbar } from 'react-scrollbars-custom'
import { NavLink } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

// import assets
import ArrowRight from 'assets/images/arrow-right.svg'
import CloseWithoutOutline from 'assets/images/close-without-outline.svg'

const Terms = () => {
  const { t } = useTranslation()
  return (
    <LandingLayout pageName="terms_and_privacy">
      <div className="w-full h-full">
        <div className="h-full pl-10 pr-5 py-3 prose bg-white rounded-lg max-w-none">
          <div className="flex flex-row items-center justify-between">
            <NavLink
              to="/"
              className="flex flex-row items-center h-12 p-2 mr-6 text-base font-medium no-underline bg-gray-300 border rounded rounded-lg hover:opacity-90 pr-4"
            >
              <img
                src={CloseWithoutOutline}
                alt="Close"
                className="pr-[8px] pb-[4px]"
              />
              {t('close')}
            </NavLink>
            <h1 className="flex justify-center pt-4 pb-4 font-semibold h-[10%]">
              {t('termsOfUse')}
            </h1>
            <NavLink
              to="/privacy"
              className="flex flex-row items-center h-12 p-2 mr-6 text-base font-medium no-underline bg-gray-300 border rounded rounded-lg hover:opacity-90"
            >
              {t('goToPrivacy')}
              <img src={ArrowRight} alt="Go to Privacy" className="pl-[8px]" />
            </NavLink>
          </div>

          <Scrollbar style={{ height: '82%' }} minimalThumbSize={200}>
            <p className="pb-2 pr-5">{t('gbtTravel')}</p>
            <h1 className="text-sm font-bold">{t('term1')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term1Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold">{t('term2')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term2Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold">{t('term3')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term3Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold">{t('term4')}</h1>
            <p>
              {t('term4Exp1')} <br />
              <p className="pl-6">
                <Trans i18nKey="term4Exp2" components={{ break: <br /> }} />
              </p>
            </p>
            <h1 className="text-sm font-bold">{t('term5')}</h1>
            <p className="pr-5">
              {t('term5Exp1')}
              <br />{' '}
              <p className="pl-6 pr-5">
                <Trans i18nKey="term5Exp2" components={{ break: <br /> }} />
              </p>
            </p>
            <h1 className="text-sm font-bold"> {t('term6')}</h1>
            <p className="pb-2 pr-5">{t('term6Exp')}</p>
            <h1 className="text-sm font-bold">{t('term7')} </h1>
            <p className="pb-2 pr-5">{t('term7Exp')}</p>
            <h1 className="text-sm font-bold">{t('term8')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term8Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold">{t('term9')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term9Exp" components={{ break: <br /> }} />
            </p>
            <h1 className="text-sm font-bold">{t('term10')}</h1>
            <p className="pr-5">
              {t('term10Exp1')} <br />
              <p className="pl-6">
                <Trans i18nKey="term10Exp2" components={{ break: <br /> }} />
              </p>
            </p>
            <h1 className="text-sm font-bold">{t('term11')}</h1>
            <p className="pb-2 pr-5">{t('term11Exp')}</p>
            <h1 className="text-sm font-bold">{t('term12')}</h1>
            <p className="pb-2 pr-5">
              <Trans i18nKey="term12Exp" components={{ break: <br /> }} />
            </p>
          </Scrollbar>
        </div>
      </div>
    </LandingLayout>
  )
}

export default Terms
