import axios from 'axios'
import { config } from 'config'

const axiosParams = {
  baseURL: `${config.WELLS_FARGO_BASE_URL}`,
}

const axiosInstance = axios.create(axiosParams)

export default axiosInstance
