//@ts-ignore
//@ts-nocheck

import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import CookiesIcon from 'assets/images/cookies-blue.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '469px',
    borderRadius: '0.75rem',
  },
}

type CookiesProps = {
  isOpen: boolean
  onClose: any
}

export default function CookiesDialog(props: CookiesProps) {
  const { t } = useTranslation()

  return (
    <div>
      {props.isOpen && (
        <Modal
          isOpen={props.isOpen}
          onRequestClose={props.onClose}
          style={customStyles}
          ariaHideApp={false}
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-40"
        >
          <div className="flex flex-col items-center justify-center gap-6 px-14 py-6">
            <img src={CookiesIcon} alt="cookie" className="w-20 h-20"></img>
            <div className="text-lg font-normal leading-8 items-center ">
              {t('cookiesHeader')}
            </div>
            <div className=" text-sm leading-6 text-center font-normal px-10">
              {t('cookiesBody')}
            </div>
            <div>
              <button
                onClick={props.onClose}
                className="px-10 py-2 text-sm font-semibold text-white transition duration-150 ease-in-out rounded-full cursor-pointer bg-blue focus:outline-none hover:opacity-90"
              >
                {t('okay')}
              </button>
            </div>
            {/* <div className="text-blue font-medium text-sm leading-6 cursor-pointer underline">
              {t('cookiesEnableSteps')}
            </div> */}
          </div>
        </Modal>
      )}
    </div>
  )
}
