import styles from './preloader.module.css'

function PreLoader() {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <span className={styles.loader}></span>
    </div>
  )
}

export default PreLoader
