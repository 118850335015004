import React from 'react'
import styles from 'components/base/PreLoader/preloader.module.css'

function VizLoaderBig() {
  return (
    <div className="relative bg-white border rounded border-light-gray drop-shadow-performanceBox h-[696px] flex items-center justify-center">
      <span className={styles.loader}></span>
    </div>
  )
}

export default VizLoaderBig