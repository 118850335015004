//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// import components
import Layout from 'components/layout'
import { useAppSelector } from 'hooks'
import { config } from 'config'
// import LastUpdatedDialog from 'components/lastUpdated'
// import { ThreeDotsAnimation } from 'components/Loading'


const ReportingHubLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)
  const last_updated = useAppSelector((state)=> state.userInfo.userAuthInfo?.last_updated)
  const [isOpen, setIsOpen] = useState(false)

  const status = 'success'

  function handleClickDialog() {
    setIsOpen(!isOpen)
  }

  return (
    <Layout>
      <div className="w-full h-full overflow-y-auto px-7 md:px-12 xl:px-8 2xl:mx-2.5 2xl:px-12 py-6 bg-light-gray-bg">
        <div className="flex flex-row items-center justify-between text-dark-blue md:h-[5%]">
          <h1 className="text-5xl font-bold tracking-wide">
            {t('reportingHubHeader')}
          </h1>
          <h2 className="text-xl tracking-wide">
            <div>
                <span className="font-semibold"> {full_name} </span>
            </div>
          </h2>
        </div>
        {children}
        <div className="flex flex-row items-end justify-end text-xs leading-[.75rem] text-gray-9 h-[5%]">
          <div>
            {/* {status === 'loading' ? (
              <ThreeDotsAnimation aspectRatio="meet" />
            ) : null} */}
            {status === 'success' ? (
              <>
                <span className="font-normal leading-4">
                  {t('lastUpdatedTill')}
                </span>{' '}
                <button
                  onClick={() => handleClickDialog()}
                  className="hover:opacity-80 hover:text-primary-blue hover:underline"
                >
                  <span className="underline font-bold decoration-1">
                    {last_updated}
                  </span>
                </button>
              </>
            ) : null}
            {/* {status === 'error' ? (
              <span className="pl-2 text-sm text-gray-600">
                {t('somethingWentWrongTryAgain')}
              </span>
            ) : null} */}
          </div>
        </div>
        {/* <LastUpdatedDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          dataSourcesData={data}
        /> */}
      </div>
    </Layout>
  )
}

export default ReportingHubLayout
