import { Link, useLocation } from 'react-router-dom'

// import components
import GlOptions from 'components/options/GlReportingOptions'
import { useTranslation } from 'react-i18next'

const GlReportingNav = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const tabs = [
    {
      title: 'Overview',
      uri: 'gl-overview',
    },
  ]

  return (
      <div className="flex flex-col lg:flex-row items-center justify-between lg:h-[11.5%]">
        <li className="flex flex-row items-center overflow-x-auto w-[100%] lg:w-auto my-4 lg:my-0">
          {tabs.map((tab) => {
            const activeTab =
              pathname.includes(tab.uri) ||
              (tab.uri === 'gl-overview' && pathname === '/gl-reporting')
            return (
              <ul
                key={tab.uri}
                className="cursor-pointer flex flex-col flex-shrink-0 flex-grow-0 basis-full md:flex-shrink md:flex-grow justify-center w-[250px] 2xl:text-lg xl:text-base lg:text-sm font-semibold"
              >
                <Link
                  to={`/gl-reporting/${tab.uri}`}
                  className={`${
                    activeTab ? 'text-blue font-bold' : 'text-gray-6'
                  } flex justify-center border-l-2`}
                >
                  {tab.title}
                </Link>
                <div
                  className={`${
                    activeTab
                      ? 'pt-2 border-b-4 border-b-blue'
                      : 'pt-2 border-b-4 border-light-gray-bg'
                  }`}
                ></div>
              </ul>
            )
          })}
        </li>

        <div className="flex flex-row items-center mb-4 lg:mb-0 w-[100%] lg:w-auto">
          <GlOptions />
        </div>
      </div>
  )
}

export default GlReportingNav
