import { useTranslation } from 'react-i18next'

export const OktaUnauthorized = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="mt-6 text-dark-blue text-2xl font-medium">
        {t('unauthorizedUser')}
      </div>
      <div className="mt-3 text-xl font-normal text-blue-grey w-[640px] text-center leading-[30px]">
        {t('pleaseWait')}
      </div>
    </>
  )
}
