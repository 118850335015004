//@ts-nocheck
import { useQuery } from "@tanstack/react-query";
import axiosInstance from 'api'
import { useAppSelector } from 'hooks'
import { config } from 'config'

const useFetchData = (key, url) => {
  const authInfo = useAppSelector((state) => state.userInfo);
  const auth = {
    Authorization: authInfo?.tokens?.Authorization,
    Authentication: authInfo?.tokens?.Authentication,
    nonce: authInfo?.tokens?.nonce,
  };

  return useQuery({
    queryKey: [key],
    queryFn: async () => {
      const response = await axiosInstance.get(url, {
        headers: {
          apikey: config.APIKEY,
          Authorization: auth.Authorization,
          Authentication: auth.Authentication,
          nonce: auth.nonce,
        },
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
};

export default useFetchData;
