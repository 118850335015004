import { config } from 'config'
import { createSlice } from '@reduxjs/toolkit'

interface TableauSessionState {
    version?: string,
    isTableauSessionDashboardRendered: boolean
}

const initialState: TableauSessionState = {
    version: config.APP_VERSION,
    isTableauSessionDashboardRendered: false,
}

export const tableauSessionSlice = createSlice({
    name: 'tableauSession',
    initialState,
    reducers: {
        updateTableauSession: (state, action) => {
            state.isTableauSessionDashboardRendered = { ...action.payload }
        },
    },
})

export const { updateTableauSession } = tableauSessionSlice.actions
export default tableauSessionSlice.reducer