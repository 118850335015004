import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorIcon from 'components/base/Icon/ErrorIcon'

//@ts-ignore
export const TableauErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation()

  return (
    <div role="alert" className="w-full h-full text-title-blue">
      <h1 className="flex flex-col items-center justify-center w-full h-full text-base text-lg font-semibold tracking-wide">
        <div className="animate-bounce">
          <ErrorIcon />
        </div>
        <div className="mt-3">{t('somethingWentWrong')}</div>
        <div className="mt-3 text-gray-9">{t('tryRefreshing')}</div>
        <button
          className="px-4 py-1 mt-3 text-base font-bold text-justify text-white rounded-full bg-blue hover:opacity-90"
          onClick={resetErrorBoundary}
        >
          {t('tryAgain')}
        </button>
      </h1>
    </div>
  )
}
