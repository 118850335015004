import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ErrorIcon from 'components/base/Icon/ErrorIcon'

//@ts-ignore
export const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <div role="alert" className="w-full h-full text-title-blue">
      <h1 className="flex flex-col items-center justify-center w-full h-full text-base text-lg font-semibold tracking-wide">
        <div className="animate-bounce">
          <ErrorIcon />
        </div>
        <div className="mt-3">404 - {t('lookLost')}</div>
        <div className="mt-3 text-gray-6"></div>
        <Link
          to="/"
          className="w-full p-4 mt-6 text-base font-medium text-center text-white border rounded-full bg-blue md:w-auto hover:opacity-90"
        >
          {t('goToHomepage')}
        </Link>
      </h1>
    </div>
  )
}
