//@ts-ignore
//@ts-nocheck
import TableauView from './Tableau'
import TableIconGray from 'assets/images/table-icon-gray.svg'
import TableIconBlue from 'assets/images/table-icon-blue.svg'
import GraphIconGray from 'assets/images/graph-icon-gray.svg'
import GraphIconBlue from 'assets/images/graph-icon-blue.svg'
import DetailsIconGray from 'assets/images/details-icon-gray.svg'
import DetailsIconBlue from 'assets/images/details-icon-blue.svg'
import InfoIconGray from 'assets/images/info-icon-gray.svg'
import InfoIconBlue from 'assets/images/info-icon-blue.svg'
import { useState } from 'react'
import SelectFilter from './SelectFilter'

type BoxProps = {
  title?: string
  titleInfo?: string
  tableauWorkbookName?: string
  tableauDashboardName?: string
  info?: Array<string>
  detailsView?: {
    tableauWorkbookName?: string
    tableauDashboardName?: string
  },
  tableView?: {
    tableauWorkbookName?: string
    tableauDashboardName?: string
  }
}
 
const TravelSummaryBox = (props: BoxProps) => {
  const {
    title,
    titleInfo,
    tableauWorkbookName,
    tableauDashboardName,
    info,
    detailsView,
    tableView
  } = props
  const [ selectedFilter, setSelectedFilter ] = useState('All');

  const [visibleView, setVisibleView] = useState('Graph')
  const [prevVisibleView, setPrevVisibleView] = useState('Graph')
  const infoContent = info.map((i,n) =>
    <li className='leading-8 text-[15px]' key={n}>{i}</li>
  );

  function changeVisibleView(view: string) {
    if(visibleView === view) {
      setVisibleView(prevVisibleView)
    } else {
      setPrevVisibleView(visibleView);
      setVisibleView(view)
    }
  }

  return (
      <div className="relative bg-white border rounded border-light-gray drop-shadow-performanceBox md:min-w-[554px] h-[336px] px-6 overflow-visible">
        <div className="flex flex-col justify-center items-between h-[50px]">
              <span className="flex flex-row">
                <div className="pt-4 text-base font-bold text-gray-9 flex flex-col">
                  {title}
                </div>
                <div className="pt-4 flex justify-end grow space-x-2">
                  { tableauDashboardName === "Advance_Purchase" && <SelectFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} /> }
                  <img onClick={() => changeVisibleView('Table')} onKeyPress={() => changeVisibleView('Table')} src={visibleView === 'Table' ? TableIconBlue : TableIconGray} alt="Table" className="cursor-pointer" tabIndex="0" />
                  <img onClick={() => changeVisibleView('Graph')} onKeyPress={() => changeVisibleView('Graph')} src={visibleView === 'Graph' ? GraphIconBlue : GraphIconGray} alt="Graph" className="cursor-pointer" tabIndex="0" />
                  {detailsView && (
                    <img onClick={() => changeVisibleView('Details')} onKeyPress={() => changeVisibleView('Details')} src={visibleView === 'Details' ? DetailsIconBlue : DetailsIconGray} alt="Detailed Reports" className="cursor-pointer" tabIndex="0"/>
                  )}
                  <img onClick={() => changeVisibleView('Info')} onKeyPress={() => changeVisibleView('Info')} src={visibleView === 'Info' ? InfoIconBlue : InfoIconGray} alt="Info" className="cursor-pointer" tabIndex="0"/>
                </div>
              </span>
              <p className="text-xs text-gray-9">{titleInfo}</p>
        </div>
        <div className='relative'>
          {(visibleView === 'Graph') ? (
            <TableauView
              tableauWorkbookName={tableauWorkbookName}
              tableauDashboardName={tableauDashboardName}
              selectedFilter={tableauDashboardName === "Advance_Purchase" && selectedFilter}
            />
          ):(visibleView === 'Details') ? (
            <TableauView
              tableauWorkbookName={detailsView.tableauWorkbookName}
              tableauDashboardName={detailsView.tableauDashboardName}
              selectedFilter={tableauDashboardName === "Advance_Purchase" && selectedFilter}
            />
          ):(visibleView === 'Table') ? (
            <TableauView
              tableauWorkbookName={tableView.tableauWorkbookName}
              tableauDashboardName={tableView.tableauDashboardName}
              selectedFilter={tableauDashboardName === "Advance_Purchase" && selectedFilter}
            />
          ):(
            <div className='h-[262px] overflow-y-auto' role='alert' aria-live='assertive'>
              {infoContent}
            </div>
          )}
        </div>
      </div>
  )
}
export default TravelSummaryBox
