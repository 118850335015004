import { AxiosRequestConfig } from 'axios'
import axiosInstance from 'api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUSES } from 'models/enums'
import { updateFilters } from './filtersSlice'
import { config } from 'config'

interface OktaTokens {
  apikey: string
  Authentication: string
  Authorization: string
  nonce: string
}

interface LobInfo {
  bu_short_code: string
  bu_long_name: string
  report_level: string
}

interface RegionCountry {
  region: string
  countries: string[]
}

interface UserAuthInfo {
  user_id: string
  full_name: string
  email: string
  employee_id: string
  is_tableau_user: boolean
  status: string
  role: string
  lob_info: LobInfo[]
  regions?: RegionCountry[]
  last_updated: string
  gl_reporting: string
}

interface UserInfoState {
  authoInfostatus: STATUSES
  tokens: OktaTokens | null
  userAuthInfo: UserAuthInfo | null
}

const initialState: UserInfoState = {
  authoInfostatus: STATUSES.IDLE,
  tokens: null,
  userAuthInfo: null,
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setAuthInfoStatus: (state, action: PayloadAction<STATUSES>) => {
      state.authoInfostatus = action.payload
    },
    setTokens: (state, action: PayloadAction<OktaTokens>) => {
      state.tokens = action.payload
    },
    setUserAuthInfo: (state, action: PayloadAction<UserAuthInfo>) => {
      state.userAuthInfo = action.payload
    },
  },
})

export const { setAuthInfoStatus, setTokens, setUserAuthInfo } = userInfoSlice.actions
export default userInfoSlice.reducer

export function fetchAuthInfo() {
  return async function fetchAuthInfoThunk(
    dispatch: Function,
    getState: Function
  ) {
    dispatch(setAuthInfoStatus(STATUSES.LOADING))

    let requestConfig: AxiosRequestConfig = {
      headers: {
        apikey: config.APIKEY,
        Authorization: getState().userInfo.tokens?.Authorization,
        Authentication: getState().userInfo.tokens?.Authentication,
        nonce: getState().userInfo.tokens?.nonce,
      },
    }

    try {
      let response = await axiosInstance.get<UserAuthInfo>(
        `/auth/me`,
        requestConfig
      )
      dispatch(setUserAuthInfo(response.data))
      dispatch(setAuthInfoStatus(STATUSES.SUCCESS))
      if (getState().filters.filters.countries_filter.length === 0) {
        let countries: any = [];
        for (let region of getState().userInfo.userAuthInfo.regions) {
          countries = [...countries, ...region.countries]
        }
        countries = countries.map(function(x: any){ return x.toUpperCase(); })
        dispatch(updateFilters({
          countries_filter: countries
        }))
      }
      if (Object.keys(getState().filters.filters.lob_filter).length === 0) {
        if(getState().userInfo.userAuthInfo.role === 'super_user') {
          let lob_info_filtered = getState().userInfo.userAuthInfo.lob_info.find((item: any) => item.bu_short_code === 'WFC')
          dispatch(updateFilters({
            lob_filter: {
              value: `-${lob_info_filtered.bu_short_code}-`,
              label: `${lob_info_filtered.bu_short_code} - ${lob_info_filtered.bu_long_name} (Level ${lob_info_filtered.report_level})`
            }
          }))
        } else {
          dispatch(updateFilters({
            lob_filter: {
              value: `-${getState().userInfo.userAuthInfo.lob_info[0].bu_short_code}-`,
              label: `${getState().userInfo.userAuthInfo.lob_info[0].bu_short_code} - ${getState().userInfo.userAuthInfo.lob_info[0].bu_long_name} (Level ${getState().userInfo.userAuthInfo.lob_info[0].report_level})`
            }
          }))
        }
      }
    } catch (err: any) {
      if (err.message === 'Request failed with status code 401') {
        dispatch(setAuthInfoStatus(STATUSES.UNAUTHORIZED))
      } else {
        dispatch(setAuthInfoStatus(STATUSES.ERROR))
      }
    }
  }
}
