import React from 'react'

import SideBar from './navigation/SideBar/SideBar'

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-full h-full bg-light-gray-bg">
      <SideBar />
      {children}
    </div>
  )
}

export default Layout
