//@ts-nocheck
import Select from 'react-select'
import { useAppSelector } from 'hooks'
import { useState, useEffect } from 'react'

const SelectFilter = ({selectedFilter, setSelectedFilter}) => {
    const {
      travel_type_filter
    } = useAppSelector((state) => state.filters.filters)
    const [advancePurchaseFilters, setAdvancePurchaseFilters] = useState([])

    const customStyles = {
        option: (provided) => ({
            ...provided,
            fontSize: '0.75rem',
          }),
          control: (provided) => ({
            ...provided,
            fontSize: '0.75rem',
            minHeight: '24px',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 7.5px',
            height: '24px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          dropdownIndicator: () => ({
            height: '20px',
            margin: '0 5px',
            fontSize: '0.75rem'
          }),
    };

    useEffect(() => {
      if(travel_type_filter === "Domestic") {
        setAdvancePurchaseFilters([
          { value: 'Domestic', label: 'Domestic' }
        ])
        setSelectedFilter('Domestic')
      } else if(travel_type_filter === "International") {
        setAdvancePurchaseFilters([
          { value: 'All_Int', label: 'All' },
          { value: 'Intl Short Haul', label: 'Intl Short Haul' },
          { value: 'Intl Long Haul', label: 'Intl Long Haul' }
        ])
        setSelectedFilter('All_Int')
      } else {
        setAdvancePurchaseFilters([
          { value: 'All', label: 'All' },
          { value: 'Domestic', label: 'Domestic' },
          { value: 'Intl Short Haul', label: 'Intl Short Haul' },
          { value: 'Intl Long Haul', label: 'Intl Long Haul' }
        ])
        setSelectedFilter('All')
      }
    }, [
      travel_type_filter
    ])

    return (
        <div>
            <Select
                value={{value: selectedFilter, label: selectedFilter === 'All_Int' ? 'All' : selectedFilter}}
                options={advancePurchaseFilters}
                onChange={(selectedOption) => setSelectedFilter(selectedOption.value)}
                className="rounded-lg w-[102px] h-[24px]"
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null
                }}
            />
        </div>
    )
}

export default SelectFilter