import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

// import components
import ReportingHubLayout from './ReportingHubLayout'
import ReportingHubTable from './ReportingHubTable'

const ReportingHub = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('reportingHubPageTitle')}</title>
      </Helmet>
      <ReportingHubLayout>
        <ReportingHubTable />
      </ReportingHubLayout>
    </>
  )
}

export default ReportingHub
