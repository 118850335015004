//@ts-nocheck
import { useLocation } from 'react-router-dom'
import Select, { components } from 'react-select';
import { MouseEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    format,
    endOfMonth,
    subMonths,
    getYear,
    getMonth,
    differenceInMonths,
    parse,
    subYears
} from 'date-fns'

// import components
import { updateFilters } from 'store/filtersSlice'
import { useAppSelector, useAppDispatch } from 'hooks'
import CountryFilter from './CountryFilter';

const FiltersComponent = (props: {
    closeOptions: MouseEventHandler<HTMLButtonElement>
    closeByOutsideClick: boolean
    resetCloseByOutsideClick: Function
}) => {
    const { pathname } = useLocation()
    const { t } = useTranslation()
    const {
        closeOptions,
        closeByOutsideClick,
        resetCloseByOutsideClick,
    } = props
    const userAuthInfo = useAppSelector((state) => state.userInfo.userAuthInfo)
    const {
        dateRange: { startDate, endDate },
        previous_start_year,
        business_purpose_filter,
        travel_type_filter,
        lob_filter,
        level_filter
    } = useAppSelector((state) => state.filters.filters)
    const dispatch = useAppDispatch()
    const [ businessPurposeFilter, setBusinessPurposeFilter ] = useState(business_purpose_filter)
    const [ travelTypeFilter, setTravelTypeFilter ] = useState(travel_type_filter)
    const [ selectedLob, setSelectedLob ] = useState(lob_filter)
    const [ selectedLevel, setSelectedLevel ] = useState(level_filter)
    const [ regions, setRegions ] = useState([])
    const [ selectedYear, setSelectedYear ] = useState({ value: getYear(parse(startDate, 'yyyy-MM-dd', new Date())), label: getYear(parse(startDate, 'yyyy-MM-dd', new Date())) })
    const selectedCountries = [] 

    let cutOffDay;

    if (userAuthInfo?.email.includes("amexgbt.com")) {
        cutOffDay = 14
    } else {
        cutOffDay = 19
    }

    let monthOptions = [];
    let initialMonth;

    if (differenceInMonths(parse(endDate, 'yyyy-MM-dd', new Date()), parse(startDate, 'yyyy-MM-dd', new Date())) > 5) {
        initialMonth = { value: 'All', label: 'All' };
    } else {
        initialMonth = {
            value: (getMonth(parse(startDate, 'yyyy-MM-dd', new Date())) + 1).toString().padStart(2, '0'),
            label: format(parse(startDate, 'yyyy-MM-dd', new Date()), 'MMMM'),
        };
    }

    if (selectedYear.value === getYear(new Date())) {
        const currentMonth = new Date().getMonth() + 1;

        // Adjust the end month based on the current date
        const endMonth = new Date().getDate() > cutOffDay ? currentMonth - 1 : currentMonth - 2;

        for (let index = 1; index <= endMonth; index++) {
            const monthValue = String(index).padStart(2, '0');
            const monthLabel = new Date(2022, index - 1, 1).toLocaleString('default', { month: 'long' });
            monthOptions.push({ value: monthValue, label: monthLabel });
        }

        monthOptions.unshift({ value: 'All', label: 'All' });
        if (!monthOptions.some(el => el.value === initialMonth.value)) {
            initialMonth = monthOptions[monthOptions.length - 1]
        }
    } else {
        monthOptions = [
            { value: 'All', label: 'All' },
            { value: '01', label: 'January' },
            { value: '02', label: 'February' },
            { value: '03', label: 'March' },
            { value: '04', label: 'April' },
            { value: '05', label: 'May' },
            { value: '06', label: 'June' },
            { value: '07', label: 'July' },
            { value: '08', label: 'August' },
            { value: '09', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ];
    }

    const [ selectedMonth, setSelectedMonth ] = useState(initialMonth);
    const [ selectedComparisonYear, setSelectedComparisonYear ] = useState({ value: getYear(parse(previous_start_year, 'yyyy-MM-dd', new Date())), label: getYear(parse(previous_start_year, 'yyyy-MM-dd', new Date())) })

    useEffect(() => {
        if (closeByOutsideClick) {
            resetCloseByOutsideClick()
        }
    }, [closeByOutsideClick])

    useEffect(() => {
        if (pathname.includes('digital-conferencing')) {
            setBusinessPurposeFilter('Non-Customer Facing')
        } else {
            setBusinessPurposeFilter(business_purpose_filter)
        }
    }, [pathname])

    const InputOption = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    let businessPurposeFilters = []
    if (pathname.includes('digital-conferencing')) {
        businessPurposeFilters = [
            { value: 'Non-Customer Facing', label: 'Non-Customer Facing' }
        ]
    } else {
        businessPurposeFilters = [
            { value: 'All', label: 'All' },
            { value: 'Customer Facing', label: 'Customer Facing' },
            { value: 'Non-Customer Facing', label: 'Non-Customer Facing' }
        ]
    }

    // const businessPurposeFilters = [
    //     { value: 'Customer Facing', label: 'Customer Facing' },
    //     { value: 'Non-Customer Facing', label: 'Non-Customer Facing' },
    //     { value: 'Personal', label: 'Personal' }
    // ];

    const travelTypeFilters = [
        { value: 'All', label: 'All' },
        { value: 'Domestic', label: 'Domestic' },
        { value: 'International', label: 'International' }
    ];


    const initialLobInfo = userAuthInfo?.lob_info;

    let levelOptions = [];
    levelOptions = [...new Set(initialLobInfo.map(item => item.report_level))]
        .sort((a, b) => a - b)
        .map(value => ({
            value: value,
            label: value,
        }));
    
    const canChangeLevel = levelOptions.some(option => option.value === 1);

    
    let customItems = [];
    
    if (pathname.includes('gl-reporting') && userAuthInfo?.role=='super_user') {
        // Filter and map when pathname includes 'gl-reporting' and report_level matches selectedLevel
        customItems = initialLobInfo
            .filter((item) => item.report_level == selectedLevel.value) // Compare with converted selectedLevel
            .map((item) => ({
                value: `-${item.bu_short_code}-`,
                label: `${item.bu_short_code} - ${item.bu_long_name} (Level ${item.report_level})`,
            }));
    } else {
        // Default mapping without filtering when pathname doesn't include 'gl-reporting'
        customItems = initialLobInfo.map((item) => ({
            value: `-${item.bu_short_code}-`,
            label: `${item.bu_short_code} - ${item.bu_long_name} (Level ${item.report_level})`,
        }));
    }

    if (userAuthInfo?.role === 'super_user') {
        customItems = [...customItems, {
            value: `Default`,
            label: `Default`
        }]
    }

    const currentYear = new Date().getFullYear();
    const startingYear = 2019; // You can adjust this to your preferred starting year
    const yearOptions = [];
    const comparisonYearOptions = [];

    const isAfterFeb19 = (new Date().getMonth() + 1) > 2 || ((new Date().getMonth() + 1) === 2 && (new Date().getDate()) > cutOffDay);

    for (let year = startingYear; year <= currentYear; year++) {
        if (year >= 2023) {
            yearOptions.push({ value: year, label: `${year}` });
        }
        if (![2020,2021,currentYear].includes(year)) {
            comparisonYearOptions.push({ value: year, label: `${year}` });
        }
    }

    if (!isAfterFeb19) {
        yearOptions.pop()
        comparisonYearOptions.pop()
    }

    const handleSubmit = (e: any) => {
        regions.forEach((region) => {
            region.children.forEach((country) => {
                if (region.checked === true || country.checked === true) {
                    selectedCountries.push(country.value.toUpperCase())
                }   
            })
        })

        let selectedStartMonth = selectedMonth.value
        let selectedEndMonth = selectedMonth.value

        if(selectedMonth.value === "All") {
            selectedStartMonth = "01"
            selectedEndMonth = monthOptions[monthOptions.length - 1].value
        }

        const startDate = format(
            parse(`${selectedYear.value}-${selectedStartMonth}-01`, 'yyyy-MM-dd', new Date()), 
            'yyyy-MM-dd'
        )
        const endDate = format(
            endOfMonth(
                parse(`${selectedYear.value}-${selectedEndMonth}-01`, 'yyyy-MM-dd', new Date())
            ), 
            'yyyy-MM-dd'
        )

        let previousStartDate;
        let previousEndDate;

        if(selectedMonth.value === "All") {
            previousStartDate = format(
                subYears(
                    parse(startDate, 'yyyy-MM-dd', new Date()),
                    1 
                ), 
                'yyyy-MM-dd'
            )
            previousEndDate = format(
                endOfMonth(
                    subYears(
                        parse(endDate, 'yyyy-MM-dd', new Date()),
                        1 
                    )
                ), 
                'yyyy-MM-dd'
            )
        } else {
            previousStartDate = format(
                subMonths(
                    parse(startDate, 'yyyy-MM-dd', new Date()),
                    1 
                ), 
                'yyyy-MM-dd'
            )
            previousEndDate = format(
                endOfMonth(
                    subMonths(
                        parse(endDate, 'yyyy-MM-dd', new Date()),
                        1 
                    )
                ), 
                'yyyy-MM-dd'
            )
        }

        const previousStartYear = format(
            parse(startDate, 'yyyy-MM-dd', new Date()), 
            `${selectedComparisonYear.value}-MM-dd`
        )
        const previousEndYear = format(
            endOfMonth(
                parse(format(
                    parse(startDate, `yyyy-MM-dd`, new Date()),
                    `${selectedComparisonYear.value}-MM-dd`
                ), 'yyyy-MM-dd', new Date()),
            ),
            "yyyy-MM-dd"
        );

        console.log("startdate ", startDate)
        console.log("endDate ", endDate)
        console.log("previousStartDate ", previousStartDate)
        console.log("previousEndDate ", previousEndDate)
        console.log("selectedComparisonYear ", selectedComparisonYear)
        console.log("previousStartYear ", previousStartYear)
        console.log("previousEndYear ", previousEndYear)
        console.log("businessPurposeFilter ", businessPurposeFilter)
        console.log("travelTypeFilter ", travelTypeFilter)
        console.log("selectedCountries ", selectedCountries)
        console.log("selectedLob ", selectedLob)
        console.log("selectedLevel ", selectedLevel)

        dispatch(
            updateFilters({
                dateRange: {
                    startDate: startDate,
                    endDate: endDate,
                },
                previous_start_date: previousStartDate,
                previous_end_date: previousEndDate,
                compare_with_year: selectedComparisonYear.value,
                previous_start_year: previousStartYear,
                previous_end_year: previousEndYear,
                business_purpose_filter: businessPurposeFilter,
                travel_type_filter: travelTypeFilter,
                countries_filter: selectedCountries,
                lob_filter: selectedLob,
                level_filter: selectedLevel
            })
        );

        return closeOptions(e)
    }

    return (
        <div className="p-3">
            <form>
                <span className="flex flex-row items-center justify-between">
                    <p className="text-sm font-bold">{t('filters')}</p>
                    <span className="flex flex-row">
                        <button
                            className="font-semibold text-white border border-light-blue rounded-lg text-sm bg-blue ml-[6px] hover:opacity-90 w-28 py-[5px]"
                            type="button"
                            onClick={handleSubmit}
                        >
                            {t('apply')}
                        </button>
                    </span>
                </span>

                { (!pathname.includes('reporting-hub') && !pathname.includes('gl-reporting')) && (
                    <div className="mt-4">
                        {/* Filter for Business Purpose */}
                        <label id='Business-Label' className='text-sm font-bold text-gray-9'>Business Purpose</label>
                        <Select
                            aria-labelledby='Business-Label'
                            value={{value: businessPurposeFilter, label: businessPurposeFilter}}
                            options={businessPurposeFilters}
                            onChange={(selectedOption) => setBusinessPurposeFilter(selectedOption.value)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                        {/* <Select
                            options={businessPurposeFilters}
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            hideSelectedOptions={false}
                            components={{
                                Option: InputOption
                            }}
                            onChange={(selectedOption) => setBusinessPurposeFilter(selectedOption)}
                            allowSelectAll={true}
                            value={businessPurposeFilter}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                                option: (base, { data, isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...base,
                                      backgroundColor: isSelected && "white",
                                      color: isSelected && "black",
                                    };
                                }
                            }}
                        /> */}
                    </div>
                )}
                
                { (!pathname.includes('compliance-trends') && !pathname.includes('reporting-hub')&& !pathname.includes('gl-reporting')) && (
                    <div className="mt-4">
                        {/* Filter for Travel Type */}
                        <label id='Travel-Label' className='text-sm font-bold text-gray-9'>Domestic / International</label>
                        <Select
                            aria-labelledby='Travel-Label'
                            value={{value: travelTypeFilter, label: travelTypeFilter}}
                            options={travelTypeFilters}
                            onChange={(selectedOption) => setTravelTypeFilter(selectedOption.value)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}

                { (!pathname.includes('reporting-hub') && pathname.includes('gl-reporting')) && (
                    <div className="mt-4">
                        {/* Filter by BU and Level */}
                        <label id='level-Label' className='text-sm font-bold text-gray-9'>Levels</label>
                        <Select
                            isDisabled = {!canChangeLevel}
                            aria-labelledby='level-Label'
                            value={selectedLevel}
                            options={levelOptions}
                            onChange={(selectedOption) => setSelectedLevel(selectedOption)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}
                { (!pathname.includes('reporting-hub') && (!pathname.includes('gl-reporting'))) && (
                    <div className="mt-4">
                        {/* Filter by BU and Level */}
                        <label id='LOB-Label' className='text-sm font-bold text-gray-9'>LOB</label>
                        <Select
                            aria-labelledby='LOB-Label'
                            value={selectedLob}
                            options={customItems}
                            onChange={(selectedOption) => setSelectedLob(selectedOption)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}

                { (!pathname.includes('reporting-hub') && !pathname.includes('gl-reporting')) && (
                    <CountryFilter userAuthInfo={userAuthInfo} regions={regions} setRegions={setRegions} />
                )}

                { !pathname.includes('compliance-trends') && (
                    <div className="mt-4">
                        {/* Filter for select year */}
                        <label id='Year-Label' className='text-sm font-bold text-gray-9'>Year</label>
                        <Select
                            aria-labelledby='Year-Label'
                            value={selectedYear}
                            options={yearOptions}
                            onChange={(selectedOption) => setSelectedYear(selectedOption)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}

                { !pathname.includes('compliance-trends') && (
                    <div className="mt-4">
                        {/* Filter for select month */}
                        <label id='Month-Label' className='text-sm font-bold text-gray-9'>Month</label>
                        <Select
                            aria-labelledby='Month-Label'
                            value={selectedMonth}
                            options={monthOptions}
                            onChange={(selectedOption) => setSelectedMonth(selectedOption)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}

                { (!pathname.includes('compliance-trends') && !pathname.includes('reporting-hub') && !pathname.includes('top-contributors') && !pathname.includes('gl-reporting')) && (
                    <div className="mt-4">
                        {/* Filter for select comparison year */}
                        <label id='Comparison-Year-Label' className='text-sm font-bold text-gray-9'>Comparison Year</label>
                        <Select
                            aria-labelledby='Comparison-Year-Label'
                            value={selectedComparisonYear}
                            options={comparisonYearOptions}
                            onChange={(selectedOption) => setSelectedComparisonYear(selectedOption)}
                            className="rounded-lg w-[328px] mt-1 text-sm"
                            styles={{
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                        />
                    </div>
                )}
            </form>
        </div>
    )
}

export default FiltersComponent
  