//@ts-nocheck
const envSettings = window

export const config = {
  OKTA_ISSUER: envSettings._env_.OKTA_ISSUER,
  OKTA_CLIENTID: envSettings._env_.OKTA_CLIENTID,
  WELLS_FARGO_BASE_URL: envSettings._env_.WELLS_FARGO_BASE_URL,
  APIKEY: envSettings._env_.APIKEY,
  TABLEAU_BASE_URL: envSettings._env_.TABLEAU_BASE_URL,
  APP_VERSION: envSettings._env_.APP_VERSION,
  MAINTAINENCE: envSettings._env_.MAINTAINENCE,
  LAST_UPDATED_DATE: envSettings._env_.LAST_UPDATED_DATE
}
