import { useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { config } from 'config'

// import components
import axiosInstance from 'api'

interface ResponseToken {
  token: string | null
}

interface FetchAuth {
  apikey: string,
  authorization: any
  authentication: any
  nonce: any
}
  

async function fetchTableauToken(
  auth: FetchAuth,
  isTableauSessionDashboardRendered: boolean
) {
  if (isTableauSessionDashboardRendered) {
    return null
  }

  let requestConfig: AxiosRequestConfig = {
    headers: {
      apikey: config.APIKEY,
      Authorization: auth.authorization,
      Authentication: auth.authentication,
      nonce: auth.nonce,
    },
  }

  try {
    let response = await axiosInstance.get<ResponseToken>(
      `/tableau/token`,
      requestConfig
    )

    return response.data.token
  } catch (err) {
    console.log(`Error in fetching DataSources: ${err}`)
  }
}

export const useTableauToken = (
  auth: FetchAuth,
  isTableauSessionDashboardRendered: boolean
) => {
  return useQuery(['tableau'], () =>
    fetchTableauToken(auth, isTableauSessionDashboardRendered)
  )
}
