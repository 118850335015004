// import components
import Tableau from 'components/tableau/Tableau'
import { useAppSelector, useTableauToken } from 'hooks'
import { config } from 'config'

const TableauSession = () => {
  const authInfo = useAppSelector((state) => state.userInfo)
  const auth = {
    apikey: config.APIKEY,
    authorization: authInfo?.tokens?.Authorization,
    authentication: authInfo?.tokens?.Authentication,
    nonce: authInfo?.tokens?.nonce
  }
  const isTableauSessionDashboardRendered = useAppSelector(
    (state) => state.tableauSession.isTableauSessionDashboardRendered
  )

  const { data } = useTableauToken(auth, isTableauSessionDashboardRendered)


  return (
    <>
      {/* Render hidden tableau dashboard for creating session with Tableau */}
      <div className="w-0 h-0 absolute left-[-500px]">
        {data && !isTableauSessionDashboardRendered ? (
          <Tableau
            tableauWorkbookName="SessionLoading"
            tableauDashboardName="SessionDashboard"
            token={data}
            isSessionDashboard={true}
          />
        ) : null}
      </div>
    </>
  )
}

export default TableauSession
