//@ts-ignore
//@ts-nocheck
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'hooks'

// import components
import TravelSummaryBox from './TravelSummaryBox'
import VizLoader from 'components/loaders/VizLoader'

const Overview = () => {
  const { t } = useTranslation()
  const isTableauSessionDashboardRendered = useAppSelector(
    (state) => state.tableauSession.isTableauSessionDashboardRendered
  )
  const OVERVIEW_DASHBOARD_DATA = t('OVERVIEW_DASHBOARD_DATA', {
    returnObjects: true,
  })
  return (
    <>
      <div className="grid 2xl:max-h-[78.5%] grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6">
        {OVERVIEW_DASHBOARD_DATA.map((box) => {
          return (
            isTableauSessionDashboardRendered ? (
              <TravelSummaryBox 
                key={box.title}
                title={box.title}
                tableauWorkbookName={box.tableauWorkbookName}
                tableauDashboardName={box.tableauDashboardName}
                info={box.info}
                detailsView={box?.detailsView}
                tableView={box.tableView}
              />
            ) : (
              <VizLoader key={box.title} />
            )
          )
        })}
        {/* <div className="bg-white max-w-[904px] min-h-[336px] rounded border border-light-gray dropShadow-performanceBox"></div>
        <div className="bg-white max-w-[904px] min-h-[336px] rounded border border-light-gray dropShadow-performanceBox"></div>
        <div className="bg-white max-w-[904px] min-h-[336px] rounded border border-light-gray dropShadow-performanceBox"></div>
        <div className="bg-white max-w-[904px] min-h-[336px] rounded border border-light-gray dropShadow-performanceBox"></div> */}
      </div>
    </>
  )
}

export default Overview
