import { Link, useLocation } from 'react-router-dom'

// import components
import AllOptions from 'components/options'
import { useTranslation } from 'react-i18next'

const TravelSummaryNav = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const tabs = [
    {
      title: t('overview'),
      uri: 'overview',
    },
    {
      title: t('travelGuidelines'),
      uri: 'travel-guidelines',
    },
    {
      title: t('meals'),
      uri: 'meals',
    },
    {
      title: t('complianceTrends'),
      uri: 'compliance-trends',
    }
  ]

  return (
      <div className="flex flex-col lg:flex-row items-center justify-between lg:h-[11.5%]">
        <ul className="flex flex-row items-center overflow-x-auto w-[100%] lg:w-auto my-4 lg:my-0">
          {tabs.map((tab) => {
            const activeTab =
              pathname.includes(tab.uri) ||
              (tab.uri === 'overview' && pathname === '/travel-summary')
            return (
              <li
                key={tab.uri}
                className="cursor-pointer flex flex-col flex-shrink-0 flex-grow-0 basis-full md:flex-shrink md:flex-grow justify-center 2xl:w-60 xl:w-44 lg:w-[9.5rem] 2xl:text-lg xl:text-base lg:text-sm font-semibold"
              > 
                <Link
                  to={`/travel-summary/${tab.uri}`}
                  className={`${
                    activeTab ? 'text-blue font-bold aria-selected' : 'text-gray-6'
                  } flex justify-center border-l-2`}
                >
                  {tab.title}
                </Link>
                <div
                  className={`${
                    activeTab
                      ? 'pt-2 border-b-4 border-b-blue'
                      : 'pt-2 border-b-4 border-light-gray-bg'
                  }`}
                ></div>
              </li>
            )
          })}
        </ul>

        <div className="flex flex-row items-center mb-4 lg:mb-0 w-[100%] lg:w-auto">
          <AllOptions />
        </div>
      </div>
  )
}

export default TravelSummaryNav
