import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

// import components
import TableauSession from 'components/tableau/TableauSession'
import GlReportingLayout from './GLReportingLayout'
import { Outlet } from 'react-router-dom'
import GlReportingNav from './GLReportingNav'

const GLReporting = () => {
  const { t } = useTranslation()

  return (
    <>
      <TableauSession />
      <Helmet>
        <title>GL Reporting</title>
      </Helmet>
      <GlReportingLayout>
        <GlReportingNav/>
        <Outlet />
      </GlReportingLayout>
    </>
  )
}

export default GLReporting
