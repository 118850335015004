 //@ts-nocheck
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
  
// import components
import { config } from 'config'
import storageSession from 'redux-persist/lib/storage/session'
import userInfoReducer from './userInfoSlice'
import tableauSessionReducer from './tableauSessionSlice'
import filtersReducer from './filtersSlice'
  
const persistTableauSessionConfig = {
  timeout: 1000,
  key: 'tableauSession',
  storage: storageSession,
  version: config.APP_VERSION,
}

const persistFiltersConfig = {
  timeout: 1000,
  key: 'filters',
  storage: storageSession,
  version: config.APP_VERSION,
}  

const persistedTableauSessionReducer = persistReducer(
  persistTableauSessionConfig,
  tableauSessionReducer
)

const persistedFiltersReducer = persistReducer(
  persistFiltersConfig,
  filtersReducer
)

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  tableauSession: persistedTableauSessionReducer,
  filters: persistedFiltersReducer,
})
  
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
  
const persistor = persistStore(store)

function getPersitedTableauSessionVersion() {
  let persistedStorage = sessionStorage.getItem(
    `persist:${persistTableauSessionConfig.key}`
  )
  if (persistedStorage) {
    let parse = JSON.parse(persistedStorage)
    return JSON.parse(parse.version)
  }
  return null
}

function getPersitedFiltersVersion() {
  let persistedStorage = sessionStorage.getItem(
    `persist:${persistFiltersConfig.key}`
  )
  if (persistedStorage) {
    let parse = JSON.parse(persistedStorage)
    if (parse.filters) {
      return JSON.parse(parse.filters).version
    }
  }
  return null
}

if ((getPersitedTableauSessionVersion() !== persistTableauSessionConfig.version) || (getPersitedFiltersVersion() !== persistFiltersConfig.version)) {
  persistor.purge()
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
  