//@ts-nocheck
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom'

// import assets
import SearchIcon from 'assets/images/search.svg'
import XIcon from 'assets/images/blue-x.svg'

// import components
import data from './pages.json'

const LandingSearch = () => {
  const [searchInput, setSearchInput] = useState('')

  const filteredData = data.pages
    .map((page) => {
        const filteredKPIs = page.kpis
            .filter((kpi) => kpi.toLowerCase().includes(searchInput.toLowerCase()));

        if (page.name.toLowerCase().includes(searchInput.toLowerCase()) || filteredKPIs.length > 0) {
            return {
                name: page.name,
                link: page.link,
                kpis: filteredKPIs,
            };
        }

        return null;
    })
    .filter((entry) => entry !== null);

  // const filteredData = data.pages
  //   .map((post) => {
  //     const mappedData = {
  //       name: post.name,
  //       link: post.link,
  //       kpis: post.kpis
  //         .map((kpi) => {
  //           if (!kpi.toLowerCase().includes(searchInput.toLowerCase()))
  //             return null

  //           return kpi
  //         })
  //         .filter((i) => i !== null),
  //     }

  //     if (!mappedData.kpis.length) return null

  //     return mappedData
  //   })
  //   .filter((i) => i !== null)

  function handleChange(e: any) {
    e.preventDefault()
    setSearchInput(e.target.value)
  }

  return (
    <span className="relative">
      <span className="flex flex-row items-center">
        <div className="relative w-full 2xl:h-1/5 xl:h-1/5 h-1/3 md:w-[355px]">
          <img
            src={SearchIcon}
            alt="Search for KPIs"
            className="absolute top-[10px] left-3 h-[12px]"
          />
          <input
            type="text"
            placeholder="Search Reports"
            className="placeholder:text-[12px] w-full pl-8 leading-4 border-gray-200 rounded-lg outline-none border-1 focus text-slate-600 text-[12px]"
            value={searchInput}
            onChange={handleChange}
            aria-label="Search Reports"
            aria-controls="search-results"
          />
          {searchInput.length > 0 && (
            <img
              src={XIcon}
              alt="Clear Search"
              className="absolute bottom-4 right-3 cursor-pointer"
              onClick={() => setSearchInput('')}
            />
          )}
        </div>
      </span>

      {searchInput.length > 0 && (
        <div 
          id="search-results"
          role="status"
          aria-live="assertive"
          className="absolute z-10 bg-gray-50 mt-1 py-1.5 px-3 w-full rounded-lg text-dark-blue text-sm max-h-[300px]" 
          style={{ boxShadow: "0 5px 8px rgba(0,0,0,.15)" }}
        >
          <Scrollbar style={{ height: '290px' }}>
            {filteredData.map((post, i) => (
              <div key={post?.name}>
                {i === 0 ? (
                  <></>
                ) : (
                  <>
                    <hr className="border border-[F0F0F0] w-full mt-2" />
                  </>
                )}
                <Link to={`/${post?.link}`}>
                  <p className="underline py-2">{post?.name}</p>
                  {post?.kpis.map((kpi) => {
                    return (
                      <p className="pl-4 px-2 hover:text-blue hover:font-medium hover:bg-lightest-blue leading-6">
                        &bull; {kpi}
                      </p>
                    )
                  })}
                </Link>
              </div>
            ))}
          </Scrollbar>
        </div>
      )}
      <div aria-live="assertive" style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
        {filteredData.map((post) => `${post.name}: ${post.kpis.join(', ')}`).join('. ')}
      </div>
    </span>
  )
}

export default LandingSearch
