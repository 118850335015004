import React from 'react'
import { useAppSelector } from 'hooks'

import TableauView from './TravelSummaryBox/Tableau'
import VizLoaderBig from 'components/loaders/VizLoaderBig'

const ComplianceTrends = () => {
  const isTableauSessionDashboardRendered = useAppSelector(
    (state) => state.tableauSession.isTableauSessionDashboardRendered
  )

  return (
    <div className="h-[78.5%] overflow-x-auto">
      {isTableauSessionDashboardRendered ? (
        <TableauView
          tableauWorkbookName='Compliance_Trends'
          tableauDashboardName='Compliance_Trends'
        />
      ) : (
        <VizLoaderBig />
      )}
    </div>
  )
}

export default ComplianceTrends