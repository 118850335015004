import { ErrorBoundary } from 'react-error-boundary'

// import components
import Tableau from 'components/tableau/Tableau'
import { TableauErrorFallback } from 'components/ErrorFallback'
import errorHandler from 'utils/error'

type TableauProps = {
  tableauWorkbookName?: string
  tableauDashboardName?: string
  showHide?: number
}

const TableauView = (props: TableauProps) => {
  const { tableauWorkbookName, tableauDashboardName, showHide } = props
  return (
    <>
      <ErrorBoundary
        FallbackComponent={TableauErrorFallback}
        onError={errorHandler}
      >
        {tableauWorkbookName && tableauDashboardName && (
          <Tableau 
            tableauWorkbookName={tableauWorkbookName}
            tableauDashboardName={tableauDashboardName}
            showHide={showHide}
          />
        )}
      </ErrorBoundary>
    </>
  )
}

export default TableauView
