//@ts-ignore
//@ts-nocheck
import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// import components
import Layout from 'components/layout'
import { useAppSelector } from 'hooks'
import { config } from 'config'
// import LastUpdatedDialog from 'components/lastUpdated'
// import { ThreeDotsAnimation } from 'components/Loading'


const GlReportingLayout = ({ children }: { children: React.ReactNode }) => {
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)
  const last_updated = useAppSelector((state)=> state.userInfo.userAuthInfo?.last_updated)

  return (
    <Layout>
      <div className="w-full h-full overflow-y-auto px-7 md:px-12 xl:px-8 2xl:mx-2.5 2xl:px-12 py-6 bg-light-gray-bg">
        <div className="flex flex-row items-center justify-between text-dark-blue md:h-[5%]">
          <h1 className="text-5xl font-bold tracking-wide">
            GL Reporting
          </h1>
          <h2 className="text-xl tracking-wide">
            <div>
                <span className="font-semibold"> {full_name} </span>
            </div>
          </h2>
        </div>
        {children}
        <div className="flex flex-row items-end justify-between text-xs leading-[.75rem] text-gray-9 h-[5%]">
          <div className='ml-[1520px]'>
              <>
                <span className="font-normal leading-4">
                Last updated till 
                </span>{' '}
                <button
                  className="hover:opacity-80 hover:text-primary-blue hover:underline"
                >
                  <span className="underline font-bold decoration-1">
                    {last_updated}
                  </span>
                </button>
              </>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GlReportingLayout
