import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

// import components
import TableauSession from 'components/tableau/TableauSession'
import TravelSummaryLayout from './TravelSummaryLayout'
import TravelSummaryNav from './TravelSummaryNav'
import { Outlet } from 'react-router-dom'

const TravelSummary = () => {
  const { t } = useTranslation()

  return (
    <>
      <TableauSession />
      <Helmet>
        <title>{t('travelSummaryPageTitle')}</title>
      </Helmet>
      <TravelSummaryLayout>
        <TravelSummaryNav />
        <Outlet />
      </TravelSummaryLayout>
    </>
  )
}

export default TravelSummary
