import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// import assets
import MenuIcon from 'assets/images/filter-icon.svg'

//import components
import Filters from './Filters'


const GlOptions = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [closeByOutsideClick, setCloseByOutsideClick] = useState(false)

  function handleClick() {
    return setOpen(!open)
  }

  function closeOptions() {
    return setOpen(false)
  }

  function resetCloseByOutsideClick() {
    return setCloseByOutsideClick(false)
  }

  useEffect(() => {
    if (closeByOutsideClick) {
      resetCloseByOutsideClick()
    }
  }, [closeByOutsideClick])

  let disableOption = false

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeOptions()
          setCloseByOutsideClick(true)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className="flex flex-col items-end" ref={wrapperRef}>
      <button
        onClick={handleClick}
        disabled={disableOption}
        className={`ml-2.5 flex flex-row items-center border border-light-gray drop-shadow-options rounded-lg p-2 lg:px-3.5 lg:py-2 2xl:px-4 2xl:py-2.5 text-sm ${
          disableOption ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        <img src={MenuIcon} alt="Options" />
        <span className="hidden md:block lg:hidden 2xl:block ml-2">Filters</span>
      </button>
      <div
        className={`${
          open ? 'block z-40 absolute' : 'hidden'
        } bg-white w-[22.188rem] rounded-lg mt-14 border-[1.5px] border-gray`}
      >
        <Filters
          closeOptions={handleClick}
          closeByOutsideClick={closeByOutsideClick}
          resetCloseByOutsideClick={resetCloseByOutsideClick}
        />
      </div>
    </div>
  )
}


export default GlOptions
