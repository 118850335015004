import { useState } from 'react'
import Avatar from 'react-avatar'
import { NavLink, Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { format } from 'date-fns'

// import assets
import GBTLogo from 'assets/images/GBTLogo-PrimaryBlue.svg'

// import components
import CookiesDialog from 'components/cookies'
import { useAppSelector } from 'hooks'

const LandingLayout = ({
  children,
  pageName,
}: {
  children: React.ReactNode
  pageName?: string
}) => {
  const { t } = useTranslation()
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)
  const [isCookiesOpen, setIsCookiesOpen] = useState(false)

  function handleCookiesDialog(value: boolean) {
    setIsCookiesOpen(value)
  }

  const currentYear = format(new Date(), 'yyyy')

  const links = [
    {
      title: t('feedback'),
      uri: '/feedback',
    },
    {
      title: t('faq'),
      uri: '/faqs',
    },
    {
      title: t('terms'),
      uri: '/terms',
    },
    {
      title: t('privacy'),
      uri: '/privacy',
    },
    {
      title: t('cookies'),
      uri: '/cookies',
    },
  ]

  return (
    <div className="w-full h-full bg-lightest-blue">
      <div className="container h-full bg-lightest-blue">
        {/*  Navigation bar */}
        <div className="flex items-center justify-between h-1/10">
          <div className="flex items-center justify-start w-1/4 h-full">
            <Link to="/">
              <img
                src={GBTLogo}
                alt="American Express Global Business Travel"
                className="w-3/5 2xl:w-full xl:w-full"
              />
            </Link>
          </div>
          <div className="flex flex-row items-center justify-center w-2/4 h-full">
            <h2 className="font-semibold 2xl:leading-relaxed xl:leading-normal tracking-normal 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl text-dark-blue">{t('mainHeader')}</h2>
          </div>
          <div className="flex items-center justify-end w-1/4 h-full">
            <Avatar
              color="#006FCF"
              round
              size="40"
              textSizeRatio={2.5}
              name={full_name}
              className="cursor-pointer"
            />
          </div>
        </div>
        {/* Navigation bar */}
        <>{pageName === 'landing' ? children : null}</>
        <>
          {pageName === 'terms_and_privacy' ? (
            <div className="flex flex-col items-center h-8/10 ">
              {children}
            </div>
          ) : null}
        </>

        {/* Footer  */}
        <footer className="flex justify-between items-center h-1/10 text-[#98A2B3] 2xl:text-base xl:text-sm text-xs md:text-[0.6rem]">
          <Trans i18nKey="copyright" values={{ currentYear }} />
          <div className="flex justify-around">
            {links.map((link) => {
              return (
                <div key={`${link.title}`} className="mr-2 md:mx-2">
                  {['Cookies'].includes(link.title) ? (
                    <button
                      onClick={() => handleCookiesDialog(true)}
                      className="hover:text-primary-blue hover:underline hover:underline-offset-4"
                    >
                      {link.title}
                    </button>
                  ) : ['Feedback','FAQs'].includes(link.title) ? (
                    <span className="cursor-not-allowed">{link.title}</span>
                  ) : (
                    <NavLink
                      to={`${link.uri}`}
                      className="hover:text-primary-blue hover:underline hover:underline-offset-4"
                    >
                      {link.title}
                    </NavLink>
                  )}
                </div>
              )
            })}
          </div>
        </footer>
        {/* Footer  */}

        <CookiesDialog
          isOpen={isCookiesOpen}
          onClose={() => setIsCookiesOpen(false)}
        />
      </div>
    </div>
  )
}

export default LandingLayout
