// import components
import DatePicker from './DatePicker'
import ExportData from './Export'
import Options from './Options'

const AllOptions = () => {

  return (
    <div className="flex flex-row items-center w-full">
      {/* <DatePicker /> */}
      {/* <ExportData /> */}
      <Options />
    </div>
  )
}

export default AllOptions
