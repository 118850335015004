import { useTranslation } from 'react-i18next'

import { OktaUnauthorized } from 'components/ErrorFallback/OktaUnauthorized'

import ErrorIcon from 'components/base/Icon/ErrorIcon'

//@ts-ignore
export const SomethingWentWrong = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation()
  console.log(error, 'error in somethingwent wrong')
  return (
    <div role="alert" className="w-full h-full text-title-blue">
      <h1 className="flex flex-col items-center justify-center w-full h-full text-base text-lg font-semibold tracking-wide">
        <div className="animate-bounce">
          <ErrorIcon />
        </div>
        {error === 'Request failed with status code 401' ? (
          <>
            <OktaUnauthorized />
          </>
        ) : (
          <>
            <div className="text-dark-blue text-[28px] font-medium leading-[33px]">
              {t('somethingWrong')}
            </div>
            <div className="mt-3 text-blue-grey w-[450px] text-center leading-[30px] text-xl font-normal">
              {t('holdbreath')}
              <br></br> {t('tryLater')}
            </div>
          </>
        )}
      </h1>
    </div>
  )
}
