//@ts-nocheck
import { config } from 'config'
import {
    format,
    getDate,
    lastDayOfMonth,
    startOfMonth,
    endOfMonth,
    subMonths,
    parse
} from 'date-fns'
import { createSlice } from '@reduxjs/toolkit'

export interface Filters {
    version?: string
    dateRange: {
        startDate: string
        endDate: string
    },
    previous_start_date: string
    previous_end_date: string
    compare_with_year: string
    previous_start_year: string
    previous_end_year: string
    // business_purpose_filter: []
    business_purpose_filter: string
    travel_type_filter: string
    countries_filter: []
    lob_filter: {}
    level_filter: string
}

interface FiltersState {
    filters: Filters
}

const defaultStartDate = format(
    getDate(new Date()) < 20
        ? startOfMonth(subMonths(new Date(), 2))
        : startOfMonth(subMonths(new Date(), 1)),
        'yyyy-MM-dd'
)
const defaultEndDate = format(
    getDate(new Date()) < 20
        ? lastDayOfMonth(subMonths(new Date(), 2))
        : lastDayOfMonth(subMonths(new Date(), 1)),
        'yyyy-MM-dd'
)

const defaultPreviousStartDate = format(
    subMonths(parse(defaultStartDate, 'yyyy-MM-dd', new Date()), 1),
    "yyyy-MM-dd"
);
const defaultPreviousEndDate = format(
    lastDayOfMonth(subMonths(parse(defaultEndDate, 'yyyy-MM-dd', new Date()), 1)),
    "yyyy-MM-dd"
);

const defaultCompareWithYear = `${(parse(defaultStartDate, 'yyyy-MM-dd', new Date())).getFullYear() - 1}`

const defaultPreviousStartYear = format(
    parse(defaultStartDate, 'yyyy-MM-dd', new Date()),
    `${defaultCompareWithYear}-MM-dd`
);
const defaultPreviousEndYear = format(
    endOfMonth(
        parse(format(
            parse(defaultStartDate, `yyyy-MM-dd`, new Date()),
            `${defaultCompareWithYear}-MM-dd`
        ), 'yyyy-MM-dd', new Date()),
    ),
    "yyyy-MM-dd"
);

const defaultBusinessPurposeFilter = 'All'

// const defaultBusinessPurposeFilter = [
//     { value: 'Customer Facing', label: 'Customer Facing' },
//     { value: 'Non-Customer Facing', label: 'Non-Customer Facing' },
//     { value: 'Personal', label: 'Personal' }
// ]

const defaultTravelTypeFilter = 'All'
const defaultLevelFilter = '1'


const initialState: FiltersState = {
    filters: {
        version: config.APP_VERSION,
        dateRange: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        },
        previous_start_date: defaultPreviousStartDate,
        previous_end_date: defaultPreviousEndDate,
        compare_with_year: defaultCompareWithYear,
        previous_start_year: defaultPreviousStartYear,
        previous_end_year: defaultPreviousEndYear,
        business_purpose_filter: defaultBusinessPurposeFilter,
        travel_type_filter: defaultTravelTypeFilter,
        countries_filter: [],
        lob_filter: {},
        level_filter: defaultLevelFilter,
    },
}

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload }
        },
    },
})

export const { updateFilters } = filtersSlice.actions
export default filtersSlice.reducer
  