import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ErrorIcon from 'components/base/Icon/ErrorIcon'
import { OktaUnauthorized } from 'components/ErrorFallback/OktaUnauthorized'

export const OktaErrorComponent: React.FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (error.name && error.message) {
      console.error(
        'something went wrong while authenticating user through okta!'
      )
      console.error(`${error.name}: ${error.message}`)
      if (
        error.name === 'AuthSdkError' &&
        (error.message ===
          'Unable to retrieve OAuth redirect params from storage' ||
          error.message ===
            'Could not load PKCE codeVerifier from storage. This may indicate the auth flow has already completed or multiple auth flows are executing concurrently.')
      ) {
        navigate(`/`, { replace: true })
      }
    }
  }, [])

  return (
    <div role="alert" className="w-full h-full text-title-blue">
      <h1 className="flex flex-col items-center justify-center w-full h-full text-base text-lg font-semibold tracking-wide">
        <div className="animate-bounce">
          <ErrorIcon />
        </div>
        {error.name === 'OAuthError' &&
        error.message === 'User is not assigned to the client application.' ? (
          <OktaUnauthorized />
        ) : (
          <>
            <div className="mt-3">{t('authError')}</div>
            <div className="mt-3 text-gray-6">{t('tryAgainAdmin')}</div>
            <Link
              to="/"
              className="w-full p-4 mt-6 text-base font-medium text-center text-white border rounded-full bg-blue md:w-auto hover:opacity-90"
            >
              {t('goToHomepage')}
            </Link>
          </>
        )}
      </h1>
    </div>
  )
}
