import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

// import components
import TableauSession from 'components/tableau/TableauSession'
import SavingsOpportunityLayout from './SavingsOpportunityLayout'
import SavingsOpportunityNav from './SavingsOpportunityNav'
import { Outlet } from 'react-router-dom'

const SavingsOpportunity = () => {
  const { t } = useTranslation()

  return (
    <>
      <TableauSession />
      <Helmet>
        <title>{t('savingsOpportunityPageTitle')}</title>
      </Helmet>
      <SavingsOpportunityLayout>
        <SavingsOpportunityNav />
        <Outlet />
      </SavingsOpportunityLayout>
    </>
  )
}

export default SavingsOpportunity
